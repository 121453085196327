import React from "react"
import ReactDOM from "react-dom"
import posed, { PoseGroup } from "react-pose"
import styled from "styled-components"

const Modal = posed.div({
  enter: {
    y: 0,
    opacity: 1,
    delay: 300,
    transition: {
      y: { type: "spring", stiffness: 1000, damping: 15 },
      default: { duration: 300 },
    },
  },
  exit: {
    y: -50,
    opacity: 0,
    transition: { duration: 150 },
  },
})

const Shade = posed.div({
  enter: { opacity: 1 },
  exit: { opacity: 0 },
})

const StyledModal = styled(Modal)`
  background-color: #ffffff;
  z-index: 300;
  overflow: auto;
  max-height: 90vh;

  @media screen and (max-width: 960px) {
    // style for mobile devices
    width: 100%;
    height: auto;
    max-height: 100vh;
    padding: 40px 0 40px;
    position: absolute;
    top: 0px;
    bottom: auto;
    display: flex;
    flex-direction: column;
  }

  @media screen and (min-width: 961px) {
    // style for desktop
    display: block;
    position: absolute;
    left: auto;
    top: 50px;
    width: 460px;
    margin: 0 auto;
    padding: 21px 0 33px;
    border-radius: 9px;
    box-sizing: border-box;
  }
`

export const BaseModalContainer = Modal

const StyledShade = styled(Shade)`
  position: fixed;
  background: rgba(0, 0, 0, 0.65);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 300;
`

class PosedModal extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidUpdate({ show }) {
    if (this.props.show !== show) {
      if (this.props.show) {
        window.document.body.style.overflowY = "hidden"
        this.props.afterOpen && this.props.afterOpen()
      } else {
        this.props.beforeClose && this.props.beforeClose()
        window.document.body.style.overflowY = "auto"
      }
    }
  }

  modalGroup = () => {
    const { show, onHide, children, ModalContainer = StyledModal } = this.props

    return (
      <PoseGroup>
        {show && [
          <StyledShade key="shade" onClick={onHide} />,
          <ModalContainer key="modal">{children}</ModalContainer>,
        ]}
      </PoseGroup>
    )
  }

  render() {
    if (
      typeof window === "undefined" ||
      !window.document.getElementById("modal-root")
    ) {
      return null
    }

    return ReactDOM.createPortal(
      this.modalGroup(),
      document.getElementById("modal-root")
    )
  }
}

export default PosedModal
