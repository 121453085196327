import React from "react"
import styled from "styled-components"

import blueCheckSrc from "../imgs/blue-check.svg"

import { withSubscribeNotification } from "./NotAvailableBlueBar"

const ProxyBillingCycleCardWrapper = styled.div`
  padding: 0 10px 0 10px;
  flex: none;
  display: block;

  @media (max-width: 960px) {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
  }

  .card-container {
    background-color: ${props => (props.available ? "ffffff" : "#E0E3E5")};

    box-shadow: rgba(65, 155, 249, 0.1) 0px 0px 0px 1px,
      rgba(65, 155, 249, 0) 0px 0px 0px 0px,
      rgba(65, 155, 249, 0.08) 0px 12px 50px 0px,
      rgba(158, 158, 163, 0.25) 0px 2px 10px 0px;
    box-shadow: ${props =>
      props.selectedId === props.id
        ? "#419bf9 0px 0px 0px 4px, rgba(106, 191, 131, 0.1) 0px 0px 0px 1px, rgba(106, 191, 131, 0.1) 0px 30px 90px 0px, rgba(158, 158, 163, 0.25) 0px 2px 18px 0px;"
        : ""};
    background-color: rgb(255, 255, 255);
    will-change: transform, box-shadow;
    transform: matrix(1, 0, 0, 1, 0, 0);
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    margin-right: 70px;
    border-radius: 12px;
    width: 255px;
    min-height: 190px;
  }

  .cc2 {
    text-align: center;
    padding: 5px 30px;
    margin-top: 5px;
    border-top: 0;
  }

  .cc2-d1 {
    width: 56px;
    height: 56px;
    margin: 0;
    min-width: 56px;
    min-height: 56px;
    display: inline-block;
    position: relative;
  }

  .cc2-d1-img {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
  }

  .cc2-d2 {
    margin-left: 12px;
  }

  .cc2d2-h4 {
    text-transform: capitalize;
    color: ${props => (props.available ? "#419bf9" : "#9BA1AA")};
    width: 100%;
    overflow: hidden;
    font-size: 18px;
    line-height: 34px;
    font-weight: 700;
    letter-spacing: 0.2px;
    margin: 0px;
    padding: 0px;
  }

  .cc2d2-p {
    white-space: pre;
    font-size: 14px;
    color: ${props => (props.available ? "#6abf83" : "#9BA1AA")};
    line-height: 24px;
    letter-spacing: 0.3px;
    font-weight: 700;
    margin: 0px;
    padding: 0px;
  }

  .cc3 {
    display: flex;
    flex-direction: column;
    font-weight: 500;
    font-size: 12px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .cc3-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    & > img {
      margin-right: 5px;
    }
  }

  .cc4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }

  .ccc1b-price {
    margin: auto 0px 0px;
    display: inline-block;
    font-size: 20px;
    font-weight: 700;
    color: ${props => (props.available ? "#000000" : "#9BA1AA")};
  }

  .ccc1b-per-month {
    margin: 0px;
    display: inline-block;
    font-size: 20px;
    font-weight: 700;
    color: #9ba1aa;
  }
`

const MESSAGE = {
  WEEKLY: [
    "Your dedicated proxy server will renew every week, cancel anytime",
    "Add credit to your account to ensure your renewal",
  ],
  MONTHLY: [
    "Your dedicated proxy server will renew every month, cancel anytime",
    "Add credit to your account to ensure your renewal",
  ],
}

function ProxyBillingCycleCard({
  available,
  selectedId,
  setSelectedId,
  id,
  title,
  children,
  isMultiLocation,
  selectedMultilocation,
}) {
  const messageList = MESSAGE[title] || []

  const renderInfoBox = () => {
    return messageList.map(mess => (
      <div className="cc3-item">
        <img src={blueCheckSrc} alt="" />
        {mess}
      </div>
    ))
  }

  const renderPricingBox = () => {
    if (!isMultiLocation || !selectedMultilocation) {
      return null
    }
    const isWeekly = title.toLowerCase() === "weekly"
    return (
      <div className="cc1b-price-div">
        <p className="ccc1b-price">
          $
          {isWeekly
            ? selectedMultilocation.weeklyPrice || ""
            : selectedMultilocation.price || ""}
        </p>
        <p className="ccc1b-per-month">&nbsp;/ {isWeekly ? "week" : "month"}</p>
      </div>
    )
  }

  return (
    <ProxyBillingCycleCardWrapper
      available={available}
      selectedId={selectedId}
      id={id}
      onClick={() => available && setSelectedId(id)}
    >
      <div className="card-container">
        <div className="cc2">
          <div className="cc2-d2">
            <h4 className="cc2d2-h4">{title.toLowerCase()}</h4>
          </div>
        </div>
        <div className="cc3">{renderInfoBox()}</div>
        <div className="cc4">{renderPricingBox()}</div>
        {children}
      </div>
    </ProxyBillingCycleCardWrapper>
  )
}

export default withSubscribeNotification(ProxyBillingCycleCard)
