import styled from "styled-components"

export const MyModalHeader = styled.h3`
  text-align: center;
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  letter-spacing: 0px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
`

export const MyModalCloseTopRight = styled.div`
  top: 12px;
  right: 12px;
  cursor: pointer;
  position: absolute;
  user-select: none;
  display: block;
`

export const MyModalCloseTopRightBody = styled.div`
  display: block;
  width: 31px;
  height: 31px;
  position: relative;
  user-select: none;
`

export const MyModalCloseImg = styled.img`
  width: 31px;
  height: 31px;
`

export const MyModalBody = styled.div`
  padding: 0 10%;
`
