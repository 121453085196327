import React from "react"
import styled from "styled-components"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import prevArrowGrey from "../imgs/prev-arrow-grey.png"
import prevArrowBlue from "../imgs/prev-arrow-blue.png"
import nextArrowGrey from "../imgs/next-arrow-grey.png"
import nextArrowBlue from "../imgs/next-arrow-blue.png"

const MultiLocationProductFeaturesWrapper = styled.div`
  padding: 0 10px 0 10px;
  flex: none;
  display: block;

  @media (max-width: 960px) {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
  }
`

const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: row;
  padding: 1rem 0;
  > * {
    @media (max-width: 960px) {
      width: 100%;
      padding: 0;
    }
    @media (min-width: 961px) and (max-width: 1060px) {
      width: 46%;
    }
    @media (min-width: 1061px) {
      width: 33.33%;
    }
  }
`
const SliderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
`
const SliderPrevNext = styled.div`
  width: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
`
const SliderButton = styled.span`
  background-image: url(${props => props.backgroundImg});
  background-repeat: no-repeat;
  display: block;
  height: 22px;
  width: 22px;
  margin: 0 auto;
  margin-top: 0px;
  cursor: pointer;
  background-size: contain;

  &:hover {
    background-image: url(${props => props.backgroundImgBlue});
  }
`

const SliderCenter = styled.div`
  width: 90%;
  .slick-track {
    margin-left: 0px;
  }
`

function MultiLocationProductFeatures({ proxyTypes, children }) {
  let slider
  var settings = {
    dots: false,
    arrows: false,
    infinite: false,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
  }

  const next = () => {
    if (slider) {
      slider.slickNext()
    }
  }

  const prev = () => {
    if (slider) {
      slider.slickPrev()
    }
  }
  return (
    <MultiLocationProductFeaturesWrapper>
      <CardsContainer>
        <SliderContainer>
          <SliderPrevNext>
            <SliderButton
              onClick={e => {
                prev()
                e.preventDefault()
              }}
              backgroundImg={prevArrowGrey}
              backgroundImgBlue={prevArrowBlue}
            ></SliderButton>
          </SliderPrevNext>

          <SliderCenter>
            <Slider
              ref={c => {
                slider = c
              }}
              {...settings}
            >
              {proxyTypes.map(elem => {
                return (
                  <MultiLocationProductFeatureCard
                    key={elem.id}
                    id={elem.id}
                    available={true}
                    title={elem.title}
                    servicesOffered={elem.servicesOffered.split(",")}
                    imageUrl={elem.imageUrl}
                  />
                )
              })}
            </Slider>
          </SliderCenter>
          <SliderPrevNext>
            <SliderButton
              onClick={e => {
                next()
                e.preventDefault()
              }}
              backgroundImg={nextArrowGrey}
              backgroundImgBlue={nextArrowBlue}
            ></SliderButton>
          </SliderPrevNext>
        </SliderContainer>
        {children}
      </CardsContainer>
    </MultiLocationProductFeaturesWrapper>
  )
}

export default MultiLocationProductFeatures

const FeatureCardWrapper = styled.div`
  padding: 0 10px 0 10px;
  margin: 1rem 0;
  flex: none;
  display: block;

  .card-container {
    background-color: #ffffff;
    will-change: transform, box-shadow;
    transform: matrix(1, 0, 0, 1, 0, 0);
    display: block;
    padding: 20px 0;
    position: relative;
    margin: 0 auto 40px;
    border-radius: 12px;
  }

  .renew-proxy-img {
    height: 100%;
  }
  .ccc1a-para {
    color: rgb(102, 122, 244);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    margin: 0;
    padding: 0;
  }

  .ccc1b {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
  }

  .ccc1b-img-div {
    height: 56px;
    display: inline-block;
    position: relative;
  }

  .ccc1bid-img {
    height: 100%;
  }

  .ccc1b-h4 {
    color: #419bf9;
    padding: 0 20px;
    margin: 10px 0;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    text-align: center;
    overflow: hidden;
  }

  .cc1b-features {
    overflow-y: hidden;
    margin-bottom: 10px;
  }

  .ccc1b-lte-para {
    margin-bottom: 30px;
  }

  .ccc1b-green-para {
    color: #6abf83;
    font-weight: 800;
    margin: 0 0 4px 0;
    padding: 0;
    text-align: center;
    font-size: 14px;
  }
`
export const MultiLocationProductFeatureCard = ({
  id,
  available,
  title,
  servicesOffered,
  imageUrl,
}) => {
  return (
    <FeatureCardWrapper available={available} id={id}>
      <div className="card-container">
        <div className="ccc1">
          <div className="ccc1b">
            <div className="ccc1b-img-div">
              <img
                className="ccc1bid-img"
                src={imageUrl}
                alt="Logo of proxy type"
              ></img>
            </div>
            <h4 className="ccc1b-h4">{title}</h4>
            <div className="cc1b-features">
              {servicesOffered.map((elem, idx) => (
                <p key={idx} className="ccc1b-green-para">
                  {elem}
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </FeatureCardWrapper>
  )
}
