import React from "react"
import styled from "styled-components"

const ProxyLocationTypeCardWrapper = styled.div`
  flex: none;
  display: block;
  cursor: pointer;
  @media (max-width: 960px) {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
  }

  .card-container {
    background-color: ${props => (props.available ? "ffffff" : "#E0E3E5")};

    box-shadow: rgba(65, 155, 249, 0.1) 0px 0px 0px 1px,
      rgba(65, 155, 249, 0) 0px 0px 0px 0px,
      rgba(65, 155, 249, 0.08) 0px 12px 50px 0px,
      rgba(158, 158, 163, 0.25) 0px 2px 10px 0px;
    box-shadow: ${props =>
      props.selectedId === props.id
        ? "#419bf9 0px 0px 0px 4px, rgba(106, 191, 131, 0.1) 0px 0px 0px 1px, rgba(106, 191, 131, 0.1) 0px 30px 90px 0px, rgba(158, 158, 163, 0.25) 0px 2px 18px 0px;"
        : ""};
    background-color: rgb(255, 255, 255);
    will-change: transform, box-shadow;
    transform: matrix(1, 0, 0, 1, 0, 0);
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-right: 70px;
    border-radius: 12px;
    width: 255px;
    min-height: 120px;
  }

  .cc2 {
    text-align: center;
    padding: 5px 30px;
    margin-top: 5px;
    border-top: 0;
  }

  .cc2-d2 {
    margin-left: 12px;
  }

  .cc2d2-h4 {
    color: ${props => (props.available ? "#419bf9" : "#9BA1AA")};
    width: 100%;
    overflow: hidden;
    font-size: 18px;
    line-height: 34px;
    font-weight: 700;
    letter-spacing: 0.2px;
    margin: 0px;
    padding: 0px;
  }
`

function ProxyLocationTypeCard({
  available,
  selectedId,
  setSelectedId,
  id,
  title,
}) {
  return (
    <ProxyLocationTypeCardWrapper
      available={available}
      selectedId={selectedId}
      id={id}
      onClick={() => available && setSelectedId(id)}
    >
      <div className="card-container">
        <div className="cc2">
          <div className="cc2-d2">
            <h4 className="cc2d2-h4">{title}</h4>
          </div>
        </div>
      </div>
    </ProxyLocationTypeCardWrapper>
  )
}

export default ProxyLocationTypeCard
