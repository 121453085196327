import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Modal from "./Modal"

import emailNotification from "../imgs/get-notification.svg"
import closeLogo from "../imgs/close.png"

import {
  MyModalCloseTopRight,
  MyModalCloseTopRightBody,
  MyModalCloseImg,
  MyModalBody,
} from "./ModalHelpers"

const StyledModal = styled(MyModalBody)`
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .text {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

  .gray {
    color: #767c86;
  }

  .blue {
    color: #677df1;
  }

  img {
    padding: 28px 0;
  }
`

export default function WaitlistAddedModal(props) {
  const {
    show,
    onHide,
    waitlistedItemID,
    features,
    allIps,
    allProducts,
  } = props
  const feature = [...features, ...allIps, ...allProducts].find(
    ({ id }) => id === waitlistedItemID
  )

  if (!feature) {
    return null
  }

  const type = feature.featureType || "Product Category"
  const title = feature.title || feature.name

  return (
    <Modal show={show} onHide={onHide}>
      <MyModalCloseTopRight>
        <MyModalCloseTopRightBody onClick={onHide}>
          <MyModalCloseImg src={closeLogo} />
        </MyModalCloseTopRightBody>
      </MyModalCloseTopRight>
      <StyledModal>
        <div className="container">
          <img src={emailNotification} alt="Email notification" />
          <p className="text">
            We will notify you when
            <br />
            <span className="gray">{type}:</span>{" "}
            <span className="blue">{title}</span>
            <br />
            is available.
            <br />
            <br />
            You can manage your waitlist here:
            <br />
            <Link to="/settings/waitlist" onClick={onHide} className="blue">
              My Waitlist
            </Link>
            <br />
            <br />
            <span>
              Need more than one? <br />
              Send us a message now and <br />
              we'll take care of you.
            </span>
          </p>
        </div>
      </StyledModal>
    </Modal>
  )
}
