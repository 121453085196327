import { Container } from "unstated"

import authStore from "./auth"

import api from "../utils/api"
import deserialize from "../utils/deserialize"

class WaitlistStore extends Container {
  constructor(props) {
    super(props)
  }

  meta = {
    fetchingWaitlist: false,
  }

  state = {
    waitlists: [],
    waitlistedItemID: null,
  }

  fetchWaitlist = async skipCheck => {
    if (!skipCheck && this.meta.fetchedWaitlist) return

    this.meta.fetchedWaitlist = true

    try {
      const res = await api.get("/waitlists")
      const waitlists = await deserialize(res.data)
      this.setState({ waitlists })
      return waitlists
    } catch (e) {
      this.meta.fetchedWaitlist = false
      console.error(e)
    }
  }

  addWaitlistItem = async (id, isCategory) => {
    if (!authStore.isAuthenticated) return authStore.login()

    try {
      const key = isCategory ? "product_category_id" : "feature_id"
      await api.post("/waitlists", {
        [key]: id,
      })
      this.setState({
        showWaitlistSuccess: true,
        waitlistedItemID: id,
      })
      return this.fetchWaitlist(true)
    } catch (e) {
      console.error(e)
    }
  }

  delWaitlistItem = async id => {
    try {
      await api.delete(`/waitlists/${id}`)
      return this.fetchWaitlist(true)
    } catch (e) {
      console.error(e)
    }
  }

  hideWaitlistModal = () => this.setState({ showWaitlistSuccess: false })
}

export default WaitlistStore
