import React from "react"
import styled from "styled-components"

import { withSubscribeNotification } from "./NotAvailableBlueBar"

const MultiLocationTypeCardContainer = styled.div`
  padding: 0 10px 0 10px;
  flex: none;
  display: block;

  .card-container {
    background-color: ${props => (props.available ? "#ffffff" : "#E0E3E5")};
    box-shadow: ${props =>
      props.selectedId === props.id
        ? `#419bf9 0px 0px 0px 4px, rgba(106, 191, 131, 0.1) 0px 0px 0px 1px,
          rgba(106, 191, 131, 0.1) 0px 30px 90px 0px,
          rgba(158, 158, 163, 0.25) 0px 2px 18px 0px;`
        : `rgba(65, 155, 249, 0.1) 0px 0px 0px 1px,
          rgba(65, 155, 249, 0) 0px 0px 0px 0px,
          rgba(65, 155, 249, 0.08) 0px 12px 50px 0px,
          rgba(158, 158, 163, 0.25) 0px 2px 10px 0px`};
    will-change: transform, box-shadow;
    transform: matrix(1, 0, 0, 1, 0, 0);
    display: block;
    padding: 10px 0;
    position: relative;
    margin: 0 auto ${props => (props.available ? 40 : 50)}px;
    border-radius: 12px;
  }

  .ccc1 {
  }

  .renew-proxy-img {
    height: 100%;
  }
  .ccc1a-para {
    color: rgb(102, 122, 244);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    margin: 0;
    padding: 0;
  }

  .ccc1b {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
  }

  .ccc1b-img-div {
    height: 56px;
    display: inline-block;
    position: relative;
  }

  .ccc1bid-img {
    height: 100%;
  }

  .ccc1b-h4 {
    color: ${props => (!props.available ? "#9BA1AA" : "#419bf9")};
    padding: 0 20px;
    margin: 10px 0;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    text-align: center;
    overflow: hidden;
  }

  .cc1b-features {
    overflow-y: hidden;
    margin-bottom: 10px;
  }

  .ccc1b-lte-para {
    margin-bottom: 30px;
  }

  .ccc1b-green-para {
    color: ${props => (!props.available ? "#9BA1AA" : "#6abf83")};
    font-weight: 800;
    margin: 0 0 4px 0;
    padding: 0;
    text-align: center;
    font-size: 14px;
  }

  .cc1b-price-div {
  }

  .ccc1b-price {
    color: ${props => (!props.available ? "#9BA1AA" : "black")};
    margin: 0px;
    display: inline-block;
    font-size: 20px;
    font-weight: 700;
  }

  .ccc1b-per-month {
    margin: 0px;
    display: inline-block;
    font-size: 20px;
    font-weight: 700;
    color: #9ba1aa;
  }

  .ccc2 {
    display: flex;
    flex-flow: row;
    padding-left: 20px;
    padding-right: 20px;
  }
`

const LocationSelector = styled.div`
  box-sizing: border-box;
  outline: none;
  cursor: pointer;
  padding: 14px 24px;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.6;
  width: auto;

  @media (max-width: 960px) {
    width: 100%;
    margin: 0 0 14px;
  }
`

function MultiLocationTypeCard({ id, title, featureType, children }) {
  return (
    <MultiLocationTypeCardContainer id={id}>
      <div className="card-container">
        <div className="ccc1"></div>
        <div className="ccc1b-green-para">
          <LocationSelector>
            Name: <span className="blue">{title}</span> <br />
            Type: <span className="blue">{featureType}</span>
          </LocationSelector>
        </div>
        <div className="ccc2"></div>
        {children}
      </div>
    </MultiLocationTypeCardContainer>
  )
}

export default withSubscribeNotification(MultiLocationTypeCard)
