import React from "react"
import styled from "styled-components"

const MyButton = styled.button`
  margin: 30px 0px 30px 0px;
  padding: 10px;
  height: 40px;
  text-align: center;

  border-radius: 12px;
  border: 1px transparent;
  background-color: ${props => (props.disabled ? "#c4c7ce" : "#667af4")};
  color: #ffffff;

  width: 100%;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
`

export default function AddProxyButton({ disabled, children }) {
  return (
    <MyButton id="next_btn" disabled={disabled}>
      {children}
    </MyButton>
  )
}
