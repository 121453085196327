import React, { useState } from "react"
import styled from "styled-components"

const MyNotAvailableBlueBar = styled.div`
  position: absolute;
  width: 100%;
  height: 42px;
  bottom: ${props => (props.isCategory ? -44 : -33)}px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: ${props => (props.waitlisted ? "#8393f5" : "#667af4")};
  color: #ffffff;
  text-align: center;
  cursor: ${props => (props.waitlisting ? "progress" : "pointer")};

  .centering-div-mnabb {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
  }
`

const NotAvailableBlueBar = ({
  available,
  waitlisted,
  isCategory,
  waitlisting,
}) => {
  if (available) {
    return null
  }

  const text = waitlisted
    ? "You will be notified!"
    : "Get notified when available!"

  return (
    <MyNotAvailableBlueBar
      {...{
        isCategory,
        waitlisting,
        waitlisted,
      }}
    >
      <div className="centering-div-mnabb">{text}</div>
    </MyNotAvailableBlueBar>
  )
}

const Container = styled.div`
  position: relative;
  cursor: ${props => (props.waitlisting ? "progress" : "pointer")};
`

export const withSubscribeNotification = (Child, isCategory) => props => {
  const { id, available, waitlists, addWaitlistItem } = props
  const key = isCategory ? "productCategory" : "feature"
  const waitlisted = waitlists.find(w => w[key] && w[key].id === id)
  const [waitlisting, setWaitlisting] = useState(false)
  const handleClick = async () => {
    if (available || waitlisting || waitlisted) {
      return
    }

    await setWaitlisting(true)
    await addWaitlistItem(id, isCategory)
    setWaitlisting(false)
  }

  return (
    <Container onClick={handleClick} waitlisting={waitlisting}>
      <Child {...props}>
        <NotAvailableBlueBar
          {...{
            available,
            isCategory,
            waitlisted,
            waitlisting,
          }}
        />
      </Child>
    </Container>
  )
}

export default NotAvailableBlueBar
